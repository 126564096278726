.table{
border-radius: 25px;
border: none;
    }
    .MuiDataGrid-main ,.css-204u17-MuiDataGrid-main{
        border-radius: 25px;
    }
    .DataGrid{
        border-radius: 25px;
        height: 25px;
    }
    .center{
display: flex;
margin-left: auto;
margin-right: auto;
justify-content: center;
    }
    .flex{
      display: flex;
      justify-content: end;
    }
    .down{
        margin-left: 10px;
    }