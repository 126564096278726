.slader{
   min-width: 350px;
    background-color: #4666D1;
    height: 1000px;
}
.img{
   display: flex;
   margin: auto;
width: 100px;
    height: 100px;
}
.Logo-img1{
    margin-top: 100px;
    width: 100%;
    height: 100%;
}
.buttonitems{
    margin-top: 100px;
    text-align: center;
    width: 400px;
    /* height: 100%; */
    
  
    
}
.Active{
    background-color: #597AE9;
    border: none;
    padding: 20px 40px;
    margin: 10px;
    width: 250px;
    color: white;
    font-size: 120%;
    border-radius: 20px;
    text-align: end;
}
.NONActive{
    background-color: #4666D1;
    border: none;
    padding: 20px 40px;
    margin: 10px;
    width: 250px;
    color: white;
    font-size: 120%;
    text-align: end;
}