.Header0{
    width:100%;
        height: 150px;
       text-align: center;
     
       background: #4666D1;
       border-radius: 100% / 0 0 70% 70%;
   
}
 
.Text-Header{
    font-size: 40px;
display: flex;
justify-content: space-around;

color: white;
}
.Text-Header-flex{
    text-align: center;
}