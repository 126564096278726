html {
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  width: 100vw;
}
* {
  font-family: 'Tajawal';
}
input {
  direction: rtl;
}
/* * {
  box-sizing: border-box;
} */
@media only screen and (min-width: 1600.22047244px) {
  .Center {
    margin: 0;

    border-radius: 47px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 649px;
    display: flex;
  }

  .img-conter {
    border: none;
    background: #4666D1;
    width: 50%;
    border-radius: 47px 0 0 47px;
    height: 649px;
  }

  .img-conter1 {
    border: none;
    background: #ffff;
    width: 50%;
    border-radius: 0 47px 47px 0;
    height: 649px;
  }

  .image-background {
    width: 100%;
    height: 1000px;


    background-size: cover;
    background-position: center;
    /* background-attachment: fixed;  */
    /* background-image: url('./img/background-login.png'); */
    background-repeat: no-repeat;

  }
}
@media only screen and (max-width: 500.22047244px) {
  .image-background {
  
    height: 1000px;
    /* height:800px; */
    justify-content: center;
    background-size: cover;
    background-attachment: fixed;
    /* background-image: url('./img/background-login.png'); */
    background-repeat: no-repeat;

  }}
@media only screen and (max-width: 1600.22047244px) {
  .image-background {
  min-width: 400px;
    height: 1000px;
    /* height:800px; */
    justify-content: center;
    background-size: cover;
    background-attachment: fixed;
    /* background-image: url('./img/background-login.png'); */
    background-repeat: no-repeat;

  }


}
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }