.Doctor_Patient_info{
    background-color:  #F5F7FF;
    height: 900px;
}
.block1{
    display: flex;
  margin-left: auto ;
  margin-right: auto;
  justify-content: center;
  
}
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4666D1;

    color: white;
  }

.Doctor_Patient_info_box{
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 100px;
}
.Box-Icon{
    width: 200px;
    height: 130px;

    background-color: white;
    border-radius:20px ;
}
.Box-img{
    height: 70px; 
    width: 70px;
}
h4{
font-size: 1.4em ;
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
color:  #4666D1;
text-decoration: none;
text-decoration-line: none;
}
h3{
    font-size: 1.6em ;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color:  white;
    text-decoration: none;
    text-decoration-line: none;
    }
.Link{
    text-decoration: none;
text-decoration-line: none;
}
.Table{
   border-radius: 25px ;
}
.TextField1{
    outline: none !important;
    border-color: #4666D1;
    border-radius: 25px;
}
.MuiInputLabel-root.Mui-focused{
    border-color: #4666D1;

}
#filled-multiline-static{
    border-color: #4666D1;
}
.TextField2{
    outline: none !important;

   
}
@media only screen and (min-width: 812.22047244px) {

    .Center1{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center ;
      margin:  0 ;
      
    }
    .Center4{
        display: flex;
        justify-content:center ;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    margin: 0 8%;
    }
    .Center5{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center ;
      margin:  1% 0%;
    }
    .TextField1{
        width: 100%;
    }
    .TextField2{
        width: 90%;
        justify-content: center;       
    }
    .TextField3{
        width: 255px;
    }
    .input-space{
        width: 37%;
        height: 30px;
        border: 1px solid    #4666D1;;
        position: relative;
       color: #4666D1;
       border-radius: 5px;
       text-align: center;
       margin: 0  0 0 25px;
    }
    #container {
        height: 300px;
        width: 450px;
        border: 1px solid    #4666D1;;
        position: relative;
       color: #4666D1;
       border-radius: 25px;
       justify-content: space-between;
    }
    .space-around{
        margin-top: 15px;
    }
    .pink{
        width: 350px;
        height: 55px;
        margin: 0  0 0 20px;
        margin-top: 30px ;
        background-color: #F1F1F1;
        border-radius: 10px;
    }
    .pinkEBEFFF{
        width: 350px;
        height: 55px;
        margin: 0  0 0 20px;
        background-color: #EBEFFF;
        border-radius: 10px;
        margin-top: 30px ;
    }
    .pinkEBEFFF_space-around{
        display: flex;
        justify-content: space-around; 
        width:44%;
        height: 55px;
        margin-left: auto;
        margin-right: auto;
        background-color: #EBEFFF;
        border-radius: 10px;
    }
}


@media only screen and (max-width: 812.22047244px) {

    .TextField1{
        width: 200px;
    }
    .TextField2{
        width: 440px;
    }
    .Center1{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center ;
      margin:  0 20px;
    }
    .Center5{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center ;
      margin:  1% 14%;
    }
    .Center4{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-evenly ;
      margin:  0 20px;
    }
    .TextField3{
        width: 120px;
    }
    .input-space{
        width: 55%;
        height: 30px;
        border: 1px solid    #4666D1;;
        position: relative;
       color: #4666D1;
       border-radius: 5px;
       text-align: center;
    }
    #container {
        height: 200px;
        width: 330px;
        border: 1px solid    #4666D1;;
        position: relative;
       color: #4666D1;
       border-radius: 25px;
       justify-content: space-between;
    }
    .pink{
        width: 200px;
        height: 55px;
        margin: 0  0 0 20px;
        background-color: #F1F1F1;
        border-radius: 10px;
    }
    .pinkEBEFFF{
        width: 200px;
        height: 55px;
        margin: 0  0 0 20px;
        background-color: #EBEFFF;
        border-radius: 10px;
    }
    .pinkEBEFFF_space-around{
        display: flex;
        justify-content: space-around; 
        width: 78%;
        height: 55px;
       
        background-color: #EBEFFF;
        border-radius: 10px;
    }
}
@media only screen and (max-width: 320.22047244px) {

    .TextField1{
        width: 150px;
    }
    .TextField2{
        width: 340px;
    }
    .Center1{
     display: flex;
     margin-left: auto;
     margin-right: auto;
      
    }
    .Center4{
        display: flex;
        margin-left: auto;
        margin-right: auto;
         
       }
       .input-space{
        width: 55%;
        height: 30px;
        border: 1px solid    #4666D1;;
        position: relative;
       color: #4666D1;
       border-radius: 5px;
       text-align: center;
    }
    .pinkEBEFFF_space-around{
        display: flex;
        justify-content: space-around; 
        width: 78%;
        height: 55px;
       
        background-color: #EBEFFF;
        border-radius: 10px;
    }
}
.Text{
    
    color: #4666D1;
}
.Center3{
 
    display: flex;
    justify-content: center ;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
margin: 0 8%;
}
.m-4{
    text-align: center;

    margin: 0 1%;
}
.hidebox{
    display: none;
}


#label {
	position: absolute;
	top: -10px;
	left: 20px;
	height: 20px;
	width: 100px;
	background-color: #fff;

	text-align: center;
}
.space-around{
    display: flex;
    justify-content: space-around;
}
.input-space-around{
    width: 20%;
    height: 30px;
    border: 1px solid    #4666D1;;
	position: relative;
   color: #4666D1;
   border-radius: 5px;
   text-align: center;
}
p1{
    font-size: larger;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
h1{
    margin-top: 4%;
    padding-top: 10px;
    font-size: 24px;
    text-align: center;
    color: #4666D1;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.pinkEBEFFF_space-around >h1{
    margin-top: 2%;
    padding-top: 1px;
   
}


.Active1{
    background-color: #597AE9;
    border: none;
    padding: 20px 60px;
    margin: 10px;
    width: 440px;
    color: white;
    font-size: 24px;
}
@media screen and (min-width: 900px) {
    .row2Items1 {
        display: flex;
    
        justify-content: space-around;
    }
    .row2Items {
        display: flex;
    
        justify-content: space-around;
    }
    .itemRow {
        padding: 0 1%;
        width: 40%;
    margin:  0 9%;
    }
  }
  @media screen and (max-width: 900px) {
    .row2Items1 {
        display: flex;
        flex-direction: column-reverse;
       
    }
 
  }

.normalRow {
    display:flex;
    justify-content: start;
    flex-wrap: wrap;
    padding:0 40px;
    width: 100%;
}
.normalRow, h2 {
    padding: 0 30px
}
.row3Items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    justify-content: center;
}
.itemRow3Items {
    width: 30%;
    padding: 0 1%;
}


.item {
    display: flex;
    justify-content: end;
}

.singleItem {
    padding: 0 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.singleItem, label {
    align-items: start;
    justify-content: start;
}

.singleTextField{
    width: 100%;
}

.twoTextField{
    width: 90%;
    margin-left: 100px;
}

button:hover{
    cursor: pointer;
}

.paddingTextField{
    padding: 5px 20px;
}

.fourTextField{
    width:100% !important;
}

.row4Items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    justify-content: center;
}
.itemRow4Items {
    width: 40%;
    padding: 0 1%;
}
