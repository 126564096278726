.Text-end {
    height: 24px;
    width: 200px;
    border-radius: 25px;
    margin: 1%;
    direction:rtl;
    border: 1px #2699FB solid;
}
select {
    padding: 10px;
    padding-right: 30px;
  

  }
.block {
    text-align: right;
    margin-top: 5%;
    margin-right: 1%;
}

.down {
    color: #2699FB;
    padding-top: 1.2%;
    width: 100px;
}

.Text-textarea {

    width: 400px;
    border-radius: 25px;
    margin: 1%;
    border: 1px #2699FB solid;
    height: 100px;
}

.Box {
    display: flex;
    justify-content: end;
}

.Save-Button {
    width: 50%;
    margin-top: 10%;
    margin-left: 10%;
    margin-right: auto;
    justify-content: center;
    border-radius: 25px;
}

.swal2-select {
    width: 300px;
}