.Home{
    background-color: #E6E5E5;
}
.flex{
    display: flex;
    justify-content: space-between;
}
.Table-center{
width: 100%;
/* margin: 1%; */
/* margin-left: 2% ; */
background-color: white;
/* border-radius: 25px; */
}