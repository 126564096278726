
@media only screen and (min-width: 1600.22047244px) {
    .Login-Center{
        position: absolute;
        top: 30%;
        left: 55%;
        border: none;
    
     
    }
    .Login-Form{
        width: 100%;
    }
    .Login-Input{
        width: 300px;
        padding: 10px;
    border-radius: 25px;
    border: 1px #2699FB solid;
    direction: ltr;
    font-size: large;
    }
    .Login-Header{
        font-size: 30px;
        padding: 5px;
    margin-top:100px;
    text-align: center;
    }
    .Login-Button{
    width: 100%;
    font-size: large;
    border-radius: 25px;
    }
    .Login-label{
        text-align: right;
        margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 1600.22047244px) {
    .Center{
        display: block;
        width: 400px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        min-height:500px;
        border-radius: 50px;
      
    }
    .img-conter1> .top{
       
        height:80px;
    }
.top{
       
        height:100px;
    }
   
    .Login-Header{
        font-size: 30px;
 
    text-align: center;
    color: white;
    }
    .img-conter1{
    
        width:100%;
        height: 150px;
       text-align: center;
     
       background: #4666D1;
       
       border-radius: 100% / 0 0 70% 70%;
       border-top-left-radius: 50px;
       border-top-right-radius: 50px;
    }
    .Login-Center{
      
      
        
        padding-top: 50px;
      }
      .Login-Input{
        width: 300px;
        padding: 10px;
    border-radius: 25px;
    border: 1px #2699FB solid;
    font: em sans-serif;
    direction: ltr;
    text-align: left;
    }
    .Login-label{
        text-align: right;
        margin-bottom: 10px;
    }
  
    }
    @media only screen and (max-width: 600.22047244px) {
        .Center{
            display: block;
            width: 300px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            background-color: white;
            min-height:400px;
            border-radius: 50px;
          
        }
        .img-conter1> .top{
           
            height:30px;
        }
    .top{
           
            height:200px;
        }
       
        .Login-Header{
            font-size: 24px;
     
        text-align: center;
        color: white;
        }
        .img-conter1{
        
            width:100%;
            height: 120px;
           text-align: center;
         
           background: #4666D1;
           
           border-radius: 100% / 0 0 70% 70%;
           border-top-left-radius: 50px;
           border-top-right-radius: 50px;
        }
        .Login-Center{
          
          
            
            padding-top: 60px;
          }
          .Login-Input{
            width: 150px;
            padding: 10px;
        border-radius: 25px;
        border: 1px #2699FB solid;
        font: em sans-serif;
        direction: ltr;
        }
        .Login-label{
            text-align: right;
            margin-bottom: 10px;
        }
      
        }
      