path {
    fill: #a1a2dc;
    cursor: pointer;
}

text {
    fill: #e9e9e9;
}

#line,
#line2 {
    fill: #797be6;
}

.selected {
    fill: #F98A6B !important;
}